const API_ENDPOINT = {
  login: "admin/login",
  logout: "admin/logout",
  topics: "topic",
  admin: "admin",
  admin_profile:"admin_profile/upload_profile_image",
  loged_user:"admin/profile",
  sub_topic: "sub_topic",
  uploadTopic_image: "topic/upload_image",
  bootcamp_subtopic: "bootcamp/get_subtopics",
  bootcamp_postmodule: "bootcamp/create_module",
  bootcamp_getmodule: "bootcamp/get_modules",
  bootcamp_putmodule: "bootcamp/update_module",
  bootcamp_getquestion: "bootcamp/get_questions",
  bootcamp_postquestion: "bootcamp/create_question",
  bootcamp_putquestion: "bootcamp/update_question",
  bootcamp_uploadquestion: "bootcamp/upload_question",

  bootcamp_deletemodule: "bootcamp/delete_module",
  bootcamp_getquestionById: "bootcamp/get_question",
  bootcamp_deleteQuestion: "bootcamp/delete_question",
  bootcamp_downloadallquestions: "bootcamp/download_all_question",

  bootcamp_downloadBootcampModules: "bootcamp/download_modules",
  bootcamp_questionId: "bootcamp/next_question_id",
  /******************************drills********************* */
  drills_subtopic: "drills/get_subtopics",
  drills_postmodule: "drills/create_module",
  drills_getmodule: "drills/get_modules",
  drills_putmodule: "drills/update_module",
  drills_getquestion: "drills/get_questions",
  drills_postquestion: "drills/create_question",
  drills_putquestion: "drills/update_question",
  drills_uploadquestion: "drills/upload_question",
  drills_deletemodule: "drills/delete_module",
  drills_getquestionById: "drills/get_question",
  drills_deleteQuestion: "drills/delete_question",
  drills_downloadallquestions: "drills/download_all_questions",
  drills_downloaddrillsModules: "drills/download_modules",
  drills_questionId: "drills/next_question_id",

  notes: "notes",
  delete_notes: "notes/delete_notes",
  delete_allnotes: "notes/delete_all_notes",
  notes_subtopics: "api/qbace-admin/notes/get_all",
  allnotes: "notes/get_subtopic_notes",
  Individual_notes: "notes/get_notes",
  update_notes: "notes/update_notes",
  subtopicsNotes_download: "notes/download_subtopic_notes",
  download_notes: "notes/download_notes",
  bootcamp_checkFile: "bootcamp/validate_columns",
  drills_checkFile: "drills/validate_columns",
  /**************************mega battle question************************ */
  megabattle:"megabattle",
  megabattle_postquestion:"megabattle/upload_question",
  megabattle_checkFile:"megabattle/validate_file",
  megabattle_uploadquestion: "megabattle/upload_question",
  megabattle_puttest:"megabattle/test",
  megabattle_deleteques:"megabattle/question",
  megabattle_deletetest:"megabattle",
  megabattle_testDownload: "megabattle/download_tests",
  megabattleQuestion:"megabattle/test",
  megabattleQuestionbyid:"megabattle/question",
  megabattle_putQuestion:"megabattle/question",
  megabattle_alltestDownload:"megabattle/download_all",
  /****************************leaderboard********************* */
  leaderboard: "megabattle/leaderboard",
  user_leaderboard:"megabattle/user_leaderboard",
  //************image bank********************* */
  imageBank:"image-bank",
  // ***********reports************** */
  open_reports: "reports/open_reports",
  reports:"reports",
  close_reports:"reports/close_reports",

  // ***************newsfeed***************//
  newsfeed: "newsfeed",
  // ************mobile user*******************//
  mobileuser:"users",
  mobileuserget:"usersget",
  mobileuserdownload:"users/download",
  courseget:"users/courses/show",
  graduation_year:"users/get/graduation_year",
  user_performance:"performance",
  moduled_topics:"performance/get_subjects",
  // ****************html*********************//
  html_convertor:"html_convertor",

    // ***************badges*************//
    badges:"rewards",
    badges_images:"rewards/upload_image",
    next_badges:"rewards/getlevel",

    // ***************inquiry************//
    inquiry:"inquiry",
    // *********************subscription********************//
    subscription:"subscription",
    subscription_history:"subscription/history",
    override:"subscription/override_best_value",
    // ****************faq****************//
    faq:"faq",

    // ***************notification********************//
    notification:"notification",
    notification_type:"notification/get_notification_types",
    notification_users:"notification/get_notification_users",
    notification_resend:"notification/resend_notification",
};

export { API_ENDPOINT };
